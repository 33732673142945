<template>
  <div>
    <v-tabs background-color="background lighten-1">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :to="{name: 'All Users'}">Users</v-tab>
      <v-tab :to="{name: 'Open Invitations'}">Open Invitation</v-tab>
      <v-tab :to="{name: 'Expired Invitations'}">Expired Invitation</v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created () {
    this.$router.push({ name: 'All Users' })
  }
}
</script>

<style lang="scss" scoped>
</style>
